import React from 'react';
import { Heading, TypographyButton } from '@components/typography';
import { useIsMobile, useTranslation } from '@utils/hooks';
import Img from '../Img';
import styles from './FormView.module.scss';

type FormViewProps = {
	Form: (args: unknown) => JSX.Element;
	isLogin?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		welcomeToPairEyewear: translator('welcome-to-pair-eyewear'),
		easilyTrackOrder: translator('easily-track-order'),
		scoreAccessToOffers: translator('score-access-to-offers'),
		earnPointsShopping: translator('earn-points-shopping'),
		altSideImage: translator('alt-side-image'),
	};
};

/**
 *	Renders a given Form Component side-by-side with an Image. The arrangement of the two children
 *  changes based on viewport width. E.g, Login Page, Refer-a-friend
 */
const FormView = ({ Form, isLogin = false }: FormViewProps) => {
	const isMobile = useIsMobile();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<div className={`${styles.container} ${!isLogin && styles.reverseDirection}`}>
			<div className={styles.formWrapper}>
				<Form />
			</div>
			<div className={styles.headerWrapper}>
				{!isLogin && (
					<header className={styles.header}>
						<Heading tag='h1'>{translations.welcomeToPairEyewear}</Heading>
						<ul>
							<TypographyButton small={isMobile}>{translations.easilyTrackOrder}</TypographyButton>
							<TypographyButton small={isMobile}>{translations.scoreAccessToOffers}</TypographyButton>
							<TypographyButton small={isMobile}>{translations.earnPointsShopping}</TypographyButton>
						</ul>
					</header>
				)}
				<Img
					alt={translations.altSideImage}
					src={
						isLogin
							? 'https://cdn.shopify.com/s/files/1/1147/9910/files/778f9c5bee8dd2a3fe1950c164127405.jpg?v=1655930146'
							: 'https://cdn.shopify.com/s/files/1/1147/9910/files/group_4542_RFX_EXT.jpg?v=1725465226'
					}
					width={1263}
					height={976}
				/>
			</div>
		</div>
	);
};

export default FormView;
