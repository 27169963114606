import { Attention, Flex, Paragraph } from '@components';
import styles from './Alert.module.scss';
import { useTranslation } from '@utils/index';

type AlertProps = {
	message?: string;
	info?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		warningUpdatePasswordEmail: translator('warning-update-password-email'),
		attention: translator('attention'),
	};
};

const Alert = ({ message, info }: AlertProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	if (info) {
		const defaultInfoMessage = translations.warningUpdatePasswordEmail;
		return (
			<Flex gap={2} className={info ? styles.info : styles.hidden}>
				<Attention height={20} width={20} label={translations.attention} fill='white' />
				<Paragraph>{message ? message : defaultInfoMessage}</Paragraph>
			</Flex>
		);
	} else {
		return (
			<Flex gap={2} className={message ? styles.error : styles.hidden}>
				<Attention height={20} width={20} label={translations.attention} fill='white' />
				<Paragraph>{message}</Paragraph>
			</Flex>
		);
	}
};

export default Alert;
