import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { EMAIL_VALIDATION_REG_EX } from '@constants';
import { Alert, Button, Heading, Input, Paragraph } from '@components';
import recoverPassword from '@services/shopify/operations/recover-password';
import { MutationCustomerRecoverArgs } from '@ts/shopify-storefront-api';
import styles from './LoginForm.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		goBack: translator('go-back'),
		resetYourPassword: translator('reset-password'),
		resetPasswordEmail: translator('reset-password-email'),
		fillOutThisField: translator('fill-out-this-field'),
		includeAtSymbol: (currentEmailInput: string) => translator('include-at-symbol', { currentEmailInput }),
		email: translator('email'),
		cancel: translator('cancel'),
		submit: translator('submit'),
	};
};

const RecoveryForm = ({ cancelRecovery }: { cancelRecovery: () => void }) => {
	const router = useRouter();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm<MutationCustomerRecoverArgs>({
		mode: 'all',
		defaultValues: {
			email: '',
		},
	});

	const [submitErrors, setSubmitErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const onSubmit: SubmitHandler<MutationCustomerRecoverArgs> = async ({ email }) => {
		try {
			setLoading(true);
			await recoverPassword(email);
			setSubmitErrors([]);
			router.reload();
		} catch (error) {
			console.error('Recovery form error: ', JSON.stringify(error));
			setLoading(false);
			reset();
			return setSubmitErrors(error.errors);
		}
	};

	const currentEmailInput = watch('email');
	const isValidEmail: boolean = EMAIL_VALIDATION_REG_EX.test(currentEmailInput);

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Button
				onClick={cancelRecovery}
				color='white'
				removeEffects
				withChevron
				chevronDirection='left'
				chevronPosition='front'
				label={translations.goBack}
				padding='0'
				border='0'
				linkStyle
				extraClasses={styles.backLink}
			/>
			<Heading tag='h2' className={styles.resetPassword}>
				{translations.resetYourPassword}
			</Heading>
			<Paragraph style={{ fontSize: '1.8rem', marginBottom: '2.4rem' }} className={styles.loginParagraph}>
				{translations.resetPasswordEmail}
			</Paragraph>
			{!!submitErrors.length &&
				submitErrors.map(({ message }, index) => {
					return <Alert message={message.toString()} key={index} />;
				})}
			<Input
				{...register('email', {
					required: translations.fillOutThisField,
					pattern: {
						value: EMAIL_VALIDATION_REG_EX,
						message: translations.includeAtSymbol(currentEmailInput),
					},
				})}
				errorMessage={errors?.email?.message}
				id='RecoveryCustomerEmail'
				name='email'
				placeholder={translations.email}
				type='email'
				withIcon
			/>

			<div className={styles.buttons}>
				<Button fullWidth label={translations.cancel} color='white' onClick={() => cancelRecovery()} />
				<Button showSpinner={loading} fullWidth type='submit' label={translations.submit} disabled={!isValidEmail} />
			</div>
		</form>
	);
};

export default RecoveryForm;
