import { useState } from 'react';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CustomerAccessTokenCreateInput } from '@ts/shopify-storefront-api';
import { EMAIL_VALIDATION_REG_EX, LOCALE_CODES, MIN_VALUE, NEXT_APP_ENV } from '@constants';
import { getPasswordErrorMessage } from '@utils/constants/account';
import { Alert, Body, Button, Heading, Input } from '@components';
import { trackLogin } from '@services/analytics/trackers';
import useLogin from '@services/shopify/operations/login';
import styles from './LoginForm.module.scss';
import RecoveryForm from './RecoveryForm';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		fillOutThisField: translator('fill-out-this-field'),
		logIn: translator('log-in'),
		invalidEmailAddress: translator('invalid-email-address'),
		email: translator('email'),
		password: translator('password'),
		forgotYourPassword: translator('forgot-your-password'),
		createAccount: translator('create-account'),
		signIn: translator('sign-in'),
		managePairMembership: translator('manage-pair-membership'),
		or: translator('or'),
	};
};

const LoginForm = () => {
	const router = useRouter();
	const [infoAlert, setInfoAlert] = useState(router.query.info);
	const isProd = process.env.NEXT_PUBLIC_APP_ENV === NEXT_APP_ENV.PROD;
	const isMembership = useFeatureIsOn('is-membership');
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm<CustomerAccessTokenCreateInput>({
		mode: 'onBlur',
		defaultValues: {
			email: '',
			password: '',
		},
	});
	const login = useLogin();
	const isUs = router.locale === LOCALE_CODES.US;

	const [recoveryMode, setRecoveryMode] = useState(false);
	const [submitErrors, setSubmitErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const checkoutQueryParam = router.query.checkout_url;
	const checkoutUrl =
		checkoutQueryParam &&
		!checkoutQueryParam.includes('https://paireyewear.com/rewards') &&
		`https://shop.paireyewear.com${checkoutQueryParam}`;

	const onSubmit: SubmitHandler<CustomerAccessTokenCreateInput> = async data => {
		try {
			setLoading(true);
			await login(data);
			setInfoAlert(null);
			setSubmitErrors([]);
			if (isProd) trackLogin();
			// Handles Yotpo Rewards page issue
			if (checkoutQueryParam && checkoutQueryParam.includes('https://paireyewear.com/rewards')) {
				router.push('/rewards');
				reset();
			}
			const returnUrl = (router.query.returnUrl as string) || '/';
			router.push(returnUrl);
			reset();
		} catch (error) {
			setLoading(false);
			reset();
			setInfoAlert(null);
			return setSubmitErrors(error.errors);
		}
	};

	// Handle redirect back to checkout for users who login at checkout
	const onSubmitCheckoutRedirect: SubmitHandler<CustomerAccessTokenCreateInput> = async data => {
		try {
			setLoading(true);
			const token = await login(data);
			setInfoAlert(null);
			setSubmitErrors([]);
			if (isProd) trackLogin();
			const res = await fetch('/api/multipass', {
				method: 'POST',
				body: JSON.stringify({
					email: data.email,
					accessToken: token,
					return_to: checkoutUrl,
				}),
			});
			const { url } = await res.json();
			router.push(url.replace('pair-eyewear.myshopify.com', 'shop.paireyewear.com'));
			reset();
		} catch (error) {
			setLoading(false);
			reset();
			setInfoAlert(null);
			return setSubmitErrors(error.errors);
		}
	};

	const currentEmailInput = watch('email');
	const currentPasswordInput = watch('password');
	const isValidEmail: boolean = EMAIL_VALIDATION_REG_EX.test(currentEmailInput);
	const storeUrl = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN;
	const loginSubscribfy = `https://${storeUrl}/apps/subscribfy-api/dashboard-login.php`;

	return recoveryMode ? (
		<RecoveryForm cancelRecovery={() => setRecoveryMode(false)} />
	) : (
		<form
			className={styles.container}
			onSubmit={checkoutUrl ? handleSubmit(onSubmitCheckoutRedirect) : handleSubmit(onSubmit)}
		>
			<Heading tag='h1' style={{ marginBottom: '2.4rem' }}>
				{translations.logIn}
			</Heading>
			{!!submitErrors.length &&
				submitErrors.map(({ message }, index) => {
					return <Alert message={message.toString()} key={index} />;
				})}
			{infoAlert && <Alert info={!!infoAlert} />}
			<Input
				{...register('email', {
					required: translations.fillOutThisField,
					pattern: {
						value: EMAIL_VALIDATION_REG_EX,
						message: translations.invalidEmailAddress,
					},
				})}
				errorMessage={errors?.email?.message}
				id='CustomerEmail'
				name='email'
				placeholder={translations.email}
				type='email'
				withIcon
			/>
			<Input
				{...register('password', {
					required: translations.fillOutThisField,
					minLength: {
						value: MIN_VALUE,
						message: getPasswordErrorMessage(currentPasswordInput.length, locale),
					},
				})}
				errorMessage={errors?.password?.message}
				id='LoginCustomerPassword'
				name='password'
				placeholder={translations.password}
				type='password'
				withIcon
			/>
			<Button linkStyle label={translations.forgotYourPassword} onClick={() => setRecoveryMode(true)} />

			<div className={styles.buttons}>
				<Button href='/account/register' fullWidth label={translations.createAccount} color='white' />
				<Button
					showSpinner={loading}
					data-login-button
					fullWidth
					type='submit'
					label={translations.signIn}
					disabled={!(isValidEmail && currentPasswordInput.length >= MIN_VALUE)}
				/>
			</div>
			{isMembership && isUs && (
				<div className={styles.manageMembership}>
					<Body> {translations.or} </Body>
					<Button color='white' label={translations.managePairMembership} href={loginSubscribfy} target='_blank' />
				</div>
			)}
		</form>
	);
};
export default LoginForm;
